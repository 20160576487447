import styled from '@emotion/styled';
import Col from 'react-bootstrap/Col';

export const Card = styled(Col)`
  text-align: ${(props) => props.align};
  width: 45%;
  float: ${(props) => props.align};
  h2 {
    font-weight: ${({ theme }) =>
      theme.sections.headlines.large.fontWeight};
    font-size: ${({ theme }) =>
      theme.sections.headlines.large.fontSize};
    color: ${({ theme }) => theme.sections.headlines.large.color};
  }
  p {
    color: ${({ theme }) => theme.sections.headlines.large.color};
    font-size: ${({ theme }) => theme.fontSize.xlarge};
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 99vw;
`;
