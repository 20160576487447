import React, { ReactElement } from 'react';
import Layout from '../components/common/Layout';
import { MetaData } from '../components/common/Meta';
import { HeroSection } from 'src/components/HomeContent';
import AboutContent from 'src/components/AboutContent';

interface IAbout {
  location: {
    pathname: string;
  };
}

const About = ({ location }: IAbout): ReactElement => {
  return (
    <>
      <MetaData location={location} />
      <Layout isHome={false}>
        <HeroSection data={aboutPageData.hero} />
        <AboutContent
          aboutus={aboutPageData.aboutus}
          team={aboutPageData.team}
        />
      </Layout>
    </>
  );
};

const aboutPageData = {
  hero: {
    title: 'About Us',
    description:
      'Stackwalker LLC is a provider of custom web, cloud, mobile, digital, and desktop software development and consulting services to clients in every industry from hot startups to Fortune 500 companies. We also offer Devops Services and Consulting, Cloud and Infrastructure development and migration services. Finally, we help businesses deploy, manage and support enterprise business applications developed by major market leaders such as Google, Amazon Web Services and Microsoft, Cisco, Vmware, Oodo etc.',
    image: '/images/aboutus.jpg',
  },
  aboutus: {
    title: 'Who We are',
    headline:
      'We builds amazing software products, for various version of your platform. ',
    items: [
      {
        title: 'Our Mission',
        description:
          'To provide innovative, superior, high quality and affordable products that businesses, governments and customers love and our employees are proud and motivated to work on.',
        side: 'left',
      },
      {
        title: 'Our Vission',
        description:
          'To create transparent and accountable local government system in Nigeria which will be truly responsive to the genuine needs of the people.',
        side: 'right',
      },
    ],
  },
  team: {
    title: 'Our Team',
    headline:
      'We builds amazing software products, for various version of your platform. ',
    image: '/images/aboutus_footer.jpg',
  },
};

export default About;
