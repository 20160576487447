import React, { ReactElement } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CenteredContent } from 'src/components/common/Sections';
import { Card, Image } from './styles';

interface IAboutContent {
  aboutus: {
    title: string;
    headline: string;
    items: IItem[];
  };
  team: {
    title: string;
    headline: string;
    image: string;
  };
}
interface IItem {
  title: string;
  description: string;
  side: string;
}

const AboutContent = ({
  aboutus,
  team,
}: IAboutContent): ReactElement => {
  const { title, headline, items } = aboutus;
  return (
    <>
      <CenteredContent title={title} headline={headline}>
        <Row noGutters>
          {items.map((item, i) => {
            return (
              <Col lg="12" key={i}>
                <Card align={item.side}>
                  <Col>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </Col>
                </Card>
              </Col>
            );
          })}
        </Row>
      </CenteredContent>
      <CenteredContent title={team.title} headline={team.headline}>
        <Image src={team.image} />
      </CenteredContent>
    </>
  );
};

export default AboutContent;
